import React, { useState, useContext } from 'react'
import { useMediaQuery } from "react-responsive"

import { formatPrice } from "utils/format-price"
import { StoreContext } from "context/store-context"
import Heading from "components/Heading"
import Button from "components/Button"
import BasketItem from 'components/BasketItem'
import Ellipsis from 'components/Ellipsis'

import { 
    gridContainer, 
    basketSection, 
    buttonPositioning,
} from './basket.module.css'

function Basket() {
    const { checkout, loading } = useContext(StoreContext)
    const products = checkout.lineItems;
    const [numBasketItems, setNumBasketItems] = useState(products.length);
    const isMobile  = useMediaQuery({  maxWidth: 650 })

    const handleCheckout = () => {
        window.open(checkout.webUrl)
    }

    const removeBasketItem = () => {
        if (numBasketItems > 0) setNumBasketItems(numBasketItems - 1) 
    }

    const basketItems = products.map( item => <BasketItem item={ item } key={ item.id } handleItemRemove={removeBasketItem} /> )

    return (
        <section className={basketSection}>
            <Heading level='h3'>Basket</Heading>

            { numBasketItems > 0 ? 
                <>
                    <div className={gridContainer}>
                        {!isMobile && 
                        <>
                            <p>Product</p>
                            <p>Price</p>
                            <p>Quantity</p>
                            <p>Total</p>
                        </>
                        }

                        { basketItems }

                        <p></p>
                        <p></p>
                        <p></p>
                        <p>{ loading ? <Ellipsis/> : formatPrice( checkout.totalPriceV2.currencyCode, checkout.totalPriceV2.amount ) }</p>
                    </div>

                    <div className={buttonPositioning}>
                        <Button 
                            onClick={handleCheckout}
                            disabled={loading}
                        >Go to Checkout</Button>
                    </div>
                </>
                : 
                <div> 
                    <Heading level='h5'>No items currently in cart.</Heading>
                </div>
            } 
        </section>
    )
}

export default Basket