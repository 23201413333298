import * as React from 'react'
import Header from "components/Header"
import Footer from "components/Footer"
import Basket from "components/Basket"

const GeneralPage = () => {
  return (
    <main>
        <Header /> 
        <Basket/>
        <Footer />
    </main>
  )
}

export default GeneralPage