import React from 'react'

import {dot1, dot2, dot3} from './ellipsis.module.css'

function Ellipsis() {
    return (
        <>
            <span className={dot1}>.</span><span className={dot2}>.</span><span className={dot3}>.</span>
        </>
    );
}

export default Ellipsis