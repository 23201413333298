import React, { useState, useCallback } from 'react'
import debounce from "lodash.debounce"
import { useMediaQuery } from "react-responsive"
import { StoreContext } from "context/store-context"
import Heading from 'components/Heading'
import { formatPrice } from "utils/format-price"
import Ellipsis from 'components/Ellipsis'

import { 
    basketImg, 
    productSection, 
    blackCrossIcon,
    quantitySection,
    totalCostPositioning,
    mobileProductInfo,
    productText } from './basketItem.module.css'

import blackCross from 'images/black-cross.png'

function BasketItem({ item, handleItemRemove }) {
    const [quantity, setQuantity] = useState(item.quantity);
    const [isVisible, setVisible] = useState(true);

    const {
        removeLineItem,
        checkout,
        updateLineItem,
        loading,
      } = React.useContext(StoreContext)

    const { title, variant } = item;

    const uli = debounce( (value) => updateLineItem(checkout.id, item.id, value), 300 )
    const debouncedUli = useCallback((value) => uli(value), [uli])
    
    const handleQuantityChange = (value) => {
        if (value !== "" && Number(value) < 1) {
          return
        }
        setQuantity(value)
        if (Number(value) >= 1) {
            debouncedUli(value)
        }
    }

    const handleRemove = () => {
        setQuantity(0)
        setVisible(false)
        handleItemRemove();
        removeLineItem(checkout.id, item.id)
    }

    const isMobile  = useMediaQuery({  maxWidth: 650 })
    const totalCost = formatPrice( checkout.totalPriceV2.currencyCode, variant.priceV2.amount * item.quantity);
    const price = formatPrice(checkout.totalPriceV2.currencyCode, variant.priceV2.amount)

    const increment = () => {
        handleQuantityChange(Number(quantity || 0) + 1)        
    } 
    const decrement = () => handleQuantityChange(Number(quantity || 0) - 1)

    const productInfo = () => {
        if( isMobile ) {
            return (
                <div className={mobileProductInfo}>
                <div>
                    <p><span>Product</span></p>
                    <div className={productText}>
                        <Heading level='h4'>{title}</Heading> 
                        <p>{variant.title}</p> 
                    </div>
                </div>

                <div>
                    <p><span>Price</span>{ price }</p>
                </div>

                <div className={quantitySection}>
                    <p>
                    <span>Quantity</span>
                    <button
                        onClick={decrement}
                    ><svg width="9" height="1" viewBox="0 0 9 1" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="9" height="1" fill="#2F3542"/></svg></button>
                    { quantity }
                    <button
                        onClick={increment}
                    ><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z" fill="#2F3542"/></svg></button>
                    </p>
                </div>

                <div className={totalCostPositioning}>
                    <p><span>Total</span>{ totalCost }</p>
                </div>
            </div> 
            )
        }
        return (
            <>
                <div><p>{ price }</p></div>

                <div className={quantitySection}>
                    <p><button
                        onClick={decrement}
                    ><svg width="9" height="1" viewBox="0 0 9 1" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="9" height="1" fill="#2F3542"/></svg></button>
                    {quantity}
                    <button
                        onClick={increment}
                    ><svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z" fill="#2F3542"/></svg></button>
                    </p>
                </div>

                <div className={totalCostPositioning}>
                    <p>{isMobile && <span>Total</span>} { totalCost }</p>
                </div>
            </>
        )
    }
           

    if( isVisible ){
        return (
            <>    
                <div className={productSection}>
                    <img className={blackCrossIcon} src={blackCross} onClick={handleRemove} role="buton" alt="delete item"/>
                    <img className={basketImg} src={variant.image.src} alt={variant.image.alt + " image"}/>
                    {!isMobile && 
                        <div className={productText}>
                            <Heading level='h4'>{title}</Heading> 
                            <p>{variant.title}</p> 
                        </div>}
                </div>
                { productInfo() }
            </>
        )
    }
    return null;
}

export default BasketItem